import React from 'react';
import styled from '@emotion/styled';
import Page from '@atlaskit/page';
import { borderRadius, colors, gridSize } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { colorPalette } from '../utilities/color-palette';
import { SimpleCobranding, useCobranding } from '../utilities/cobranding/cobranding';
import { getCobrandedComponents } from './whitebox-cobranding';
import { ProductHeading } from '../containers/ProductHeading';

import {
  listStyles,
  renderProductLogo,
  whiteboxWidthDesktop,
  whiteboxWidthHandheld,
} from './whitebox-cobranding/shared-cobranding';
import Spinner from '@atlaskit/spinner';
import FooterComponent from './whitebox-cobranding/FooterComponent';

export interface CobrandedWhiteboxPageProps {
  pageId: string;
  children?: React.ReactNode;
  header: React.ReactNode;
  whiteboxFooter?: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  noCobranding?: boolean;
}

const maxMobileScreen = `( max-width: ${gridSize() * 100}px )`;
const smallMobileScreen = `( max-width: ${gridSize() * 47}px )`;

/*
 * Exported for tests only
 */
export const WhiteboxFooter = styled.div`
  color: ${token('color.text.subtle', colors.N500)};
  margin-top: ${gridSize() * 3}px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;

  ${listStyles}
`;

export const WhiteboxFootnote = styled.div`
  color: ${token('color.text.subtle', colors.N500)};
  padding-top: ${gridSize() * 2}px;
  font-size: 11px;
  text-align: center;
  line-height: 14px;

  ${listStyles}
`;

const Whitebox = styled.section<{ width?: string; noBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: ${({ width }) => width || whiteboxWidthDesktop};
  padding: ${gridSize() * 4}px ${gridSize() * 5}px;

  background: ${colorPalette.primaryWhite};
  border-radius: ${borderRadius()}px;
  box-shadow: ${({ noBorder }) => (noBorder ? 'none' : '0 0 10px rgba(0, 0, 0, 0.1)')};
  box-sizing: border-box;
  color: ${token('color.text.subtle', colors.N500)};

  @media ${maxMobileScreen} {
    padding: ${gridSize() * 4}px ${gridSize() * 2}px;
    background: transparent;
    box-shadow: none;
    min-height: 100%;
    width: ${whiteboxWidthHandheld};
  }

  @media ${smallMobileScreen} {
    padding: ${gridSize() * 2}px ${gridSize() * 2}px;
  }
`;

const Container = styled.div`
  min-height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;

  @media ${maxMobileScreen} {
    height: 100%;
    margin: 0;
    justify-content: flex-start;
  }
`;

const FooterSpacer = styled.div`
  @media ${maxMobileScreen} {
    margin: auto;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${gridSize() * 3}px;
`;

export const CobrandedWhiteboxPage = ({
  header,
  children,
  footer,
  whiteboxFooter,
  isLoading,
  pageId,
  noCobranding,
}: CobrandedWhiteboxPageProps) => {
  const { cobranding, isMobileApp } = useCobranding();
  const resolvedCobranding = noCobranding ? SimpleCobranding.NO_COBRANDING : cobranding;
  const {
    background: Background,
    logo,
    footerProducts,
    whiteboxWidth,
    hideWhiteboxBorder,
  } = getCobrandedComponents(resolvedCobranding);

  return (
    <Page>
      <Background>
        <Container id="WhiteboxContainer">
          <Whitebox width={whiteboxWidth} noBorder={hideWhiteboxBorder} role="main">
            {isLoading ? (
              <SpinnerContainer>
                <Spinner size="large" />
              </SpinnerContainer>
            ) : (
              <>
                <ProductHeading suffix={header}>{logo && renderProductLogo(logo)}</ProductHeading>
                {children}
                {whiteboxFooter && <WhiteboxFooter>{whiteboxFooter}</WhiteboxFooter>}
                <FooterSpacer />
                <WhiteboxFootnote>
                  {footerProducts && (
                    <FooterComponent
                      renderText={!isMobileApp}
                      pageId={pageId}
                      footerProducts={footerProducts}
                    />
                  )}
                  {footer}
                </WhiteboxFootnote>
              </>
            )}
          </Whitebox>
        </Container>
      </Background>
    </Page>
  );
};

export default CobrandedWhiteboxPage;
