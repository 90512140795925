import React from 'react';
import { useLocation } from 'react-router';
import CreateAccountConfirmation from './CreateAccountConfirmation';
import VerifyEmailOtp from './VerifyEmailOtp';
import Welcome from './Welcome';

export type ProductImageType = 'JIRA' | 'CONFLUENCE';

type ImageRouterProps = {
  readonly productImage: ProductImageType;
};

export default function ImageRouter({ productImage }: ImageRouterProps) {
  const { pathname } = useLocation();

  switch (pathname) {
    case '/signup/verify-email/otp':
      return <VerifyEmailOtp productImage={productImage} />;

    case '/signup/welcome':
      return <Welcome productImage={productImage} />;

    case '/login/google/confirmation':
    case '/login/social/confirmation':
    case '/signup/confirmation':
      return <CreateAccountConfirmation productImage={productImage} />;

    default:
      return null;
  }
}
