/* eslint-disable @atlaskit/design-system/prefer-primitives */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

import styled from '@emotion/styled';
import { HEADER_HEIGHT } from './constants';
export var Wrapper = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  '@media (min-width: 1200px)': {
    'rect.small, path.small': {
      visibility: 'hidden'
    },
    'rect.medium, path.medium': {
      visibility: 'hidden'
    },
    'rect.large, path.large': {
      visibility: 'visible'
    }
  },
  '@media (min-width: 1000px) and (max-width: 1200px)': {
    'rect.small, path.small': {
      visibility: 'hidden'
    },
    'rect.large, path.large': {
      visibility: 'hidden'
    },
    'rect.medium, path.medium': {
      visibility: 'visible'
    }
  },
  '@media (max-width: 1000px)': {
    'rect.medium, path.medium': {
      visibility: 'hidden'
    },
    'rect.large, path.large': {
      visibility: 'hidden'
    },
    'rect.small, path.small': {
      visibility: 'visible'
    }
  }
});
export var Line = styled.div({
  display: 'inline-block',
  position: 'fixed',
  top: "".concat(HEADER_HEIGHT, "px"),
  left: 0,
  height: '2px',
  width: '100%',
  background: '#dfe1e6',
  zIndex: 1
});
export var DarkModeLine = styled.div({
  display: 'inline-block',
  position: 'fixed',
  top: "".concat(HEADER_HEIGHT, "px"),
  left: 0,
  height: '2px',
  width: '100%',
  background: '#454f59',
  zIndex: 1
});