import React, { useEffect, useState } from 'react';
import ProfileMenu from './ProfileMenu';
import { useAppSelector } from '../../../../stores/redux.store';
import * as Styled from '../common/styled';
import { JiraProductIllustration } from '../common/svg/jira-product-illustration';
import { ConfluenceProductIllustration } from '../common/svg/confluence-product-illustration';
import { ProductImageType } from './ImageRouter';

type WelcomeProps = {
  readonly productImage: ProductImageType;
};

export default function Welcome({ productImage }: WelcomeProps) {
  const displayName = useAppSelector(state => state.welcome.displayName);
  const email = useAppSelector(state => state.welcome.email);
  const [isVerified, setIsVerified] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVerified(false);
    }, 1500);
    return () => clearTimeout(timer);
  });
  return (
    <Styled.ImageContainer>
      <Styled.WelcomeContainer>
        {productImage === 'JIRA' && (
          <Styled.JiraImageContainer>
            <JiraProductIllustration />
          </Styled.JiraImageContainer>
        )}
        {productImage === 'CONFLUENCE' && (
          <Styled.ConfluenceImageContainer>
            <ConfluenceProductIllustration />
          </Styled.ConfluenceImageContainer>
        )}
        <ProfileMenu name={displayName} email={email} isVerified={isVerified} />
      </Styled.WelcomeContainer>
    </Styled.ImageContainer>
  );
}
