import styled, { CSSObject } from '@emotion/styled';
import { colors, gridSize } from '@atlaskit/theme';

const maxMobileScreen = `(max-width: ${gridSize() * 118}px)`;
const modalBreakpoint = '@media (min-width: 801px)';
const imageBreakpoint = '@media (min-width: 1100px)';

const gridContainerStyles: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  boxSizing: 'border-box',
  [modalBreakpoint]: {
    alignItems: 'center',
    padding: 50,
  },
};

export const GridContainer = styled.section({
  ...gridContainerStyles,
});

export const ModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1200,
  padding: 20,
  gap: 30,

  [modalBreakpoint]: {
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: 50,
    gap: 50,
  },
});

export const ConfluenceGridContainer = styled.div({
  ...gridContainerStyles,
  paddingTop: 20,
  [modalBreakpoint]: {
    padding: 50,
  },
});

export const ConfluenceContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  padding: 20,
  [modalBreakpoint]: {
    gap: 50,
  },
});

export const FooterVariant1 = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const FooterVariant2 = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  [imageBreakpoint]: {
    justifyContent: 'right',
  },
});

export const LogoWrapper = styled.div({
  textAlign: 'center',
});

export const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const LogoContainerVariant1 = LogoContainer;

export const LogoContainerVariant2 = styled.div({
  display: 'flex',
  justifyContent: 'center',
  [imageBreakpoint]: {
    justifyContent: 'start',
  },
});

export const ContentContainer = styled.div({
  display: 'flex',
  gap: 50,
  maxWidth: '100%',
});

export const WhiteBoxContainer = styled.div({
  section: {
    padding: 0,
    margin: 0,
    width: '100%',
    maxWidth: '400px',
  },
  '#WhiteboxContainer': {
    margin: 0,
  },
  '#ProductHeading': {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  '#ProductHeadingSuffix': {
    paddingTop: 0,
    alignItems: 'flex-start',
    'h2,h3,h4,h5': {
      marginTop: 0,
      color: colors.N800,
      textAlign: 'center',
      fontSize: '35px',
      lineHeight: '40px',
    },
  },
});

export const ImageContainer = styled.div({
  display: 'none',
  flex: 1,
  [imageBreakpoint]: { display: 'block' },
});

export const JiraImageContainer = styled.div({
  svg: {
    width: '100%',
    maxWidth: '630px',
    height: 'auto',
  },
});

export const ConfluenceImageContainer = styled.div({
  svg: {
    width: '100%',
    maxWidth: '687px',
    height: 'auto',
  },
});

export const WelcomeContainer = styled.div({
  display: 'flex',
  placeItems: 'center',
  alignItems: 'flex-start',
  height: '100%',
  position: 'relative',

  [`@media screen and ${maxMobileScreen}`]: {
    display: 'none',
  },
});

export const OtpContainer = styled.div({
  display: 'flex',
  placeItems: 'center',
  height: '100%',
  alignItems: 'flex-start',
  position: 'relative',

  [`@media screen and ${maxMobileScreen}`]: {
    display: 'none',
  },
});

export const CreateAccountConfirmationContainer = styled.div({
  display: 'flex',
  placeItems: 'center',
  alignItems: 'flex-start',
  height: '100%',
  position: 'relative',

  [`@media screen and ${maxMobileScreen}`]: {
    display: 'none',
  },
});

export const WacSignupOTPExperimentContainer = styled.div({
  '#ProductHeadingSuffix h5': {
    color: colors.N800,
    marginTop: `${gridSize() * 3}px`,
    fontSize: '16px !important',
  },
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const WacSignupOTPExperimentGridContainer = styled.div({
  ...gridContainerStyles,
  minHeight: 'initial',
  [modalBreakpoint]: {
    alignItems: 'initial',
  },
});

export const WacSignupOTPExperimentContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 1200,
  padding: 24,
  gap: 30,
});

export const WacSignupOTPExperimentLogoWrapper = styled.div({
  textAlign: 'center',
  marginTop: `${gridSize() * 3}px`,
  marginBottom: 90,
});
