import {
  AdvancedCobranding,
  isSimpleCobranding,
  SimpleCobranding,
} from '../../utilities/cobranding/cobranding';
import { CobrandingComponents } from './shared-cobranding';

import DefaultCobranding from './DefaultCobranding';
import BitbucketCobranding from './bitbucket/BitbucketCobranding';
import CompassCobranding from './compass/CompassCobranding';
import ConfluenceCobranding from './confluence/ConfluenceCobranding';
import JiraCobranding from './jira/JiraCobranding';
import OpsgenieCobranding from './opsgenie/OpsgenieCobranding';
import StatuspageCobranding from './statuspage/StatuspageCobranding';
import TrelloCobranding from './trello/TrelloCobranding';
import WacCobranding from './wac/WacCobranding';
import AtlasCobranding from './atlas/AtlasCobranding';
import WacSignupOTPExperimentJira from './wac/WacSignupOTPExperimentJira';
import WacSignupOTPExperimentConfluence from './wac/WacSignupOTPExperimentConfluence';
import BXPSU2453Variant1Cobranding from './wac/bxpsu2453-v1/BXPSU2453Variant1Cobranding';
import BXPSU2453Variant2Cobranding from './wac/bxpsu2453-v2/BXPSU2453Variant2Cobranding';
import BXPSU2618Variant1Cobranding from './wac/bxpsu2618-v1/BXPSU2618Variant1Cobranding';
import LoomCobranding from './loom/LoomCobranding';
import JiraAlignCobranding from './jira-align/JiraAlignCobranding';

const cobrandingMap: { [K in SimpleCobranding]: CobrandingComponents } = {
  [SimpleCobranding.NO_COBRANDING]: DefaultCobranding,
  [SimpleCobranding.BITBUCKET]: BitbucketCobranding,
  [SimpleCobranding.COMPASS]: CompassCobranding,
  [SimpleCobranding.CONFLUENCE]: ConfluenceCobranding,
  [SimpleCobranding.JIRA]: JiraCobranding,
  [SimpleCobranding.LOOM]: LoomCobranding,
  [SimpleCobranding.JIRA_ALIGN]: JiraAlignCobranding,
  [SimpleCobranding.OPSGENIE]: OpsgenieCobranding,
  [SimpleCobranding.STATUSPAGE]: StatuspageCobranding,
  [SimpleCobranding.TRELLO]: TrelloCobranding,
  [SimpleCobranding.WAC]: WacCobranding,
  [SimpleCobranding.ATLAS]: AtlasCobranding,
  [SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_JIRA]: WacSignupOTPExperimentJira,
  [SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_CONFLUENCE]: WacSignupOTPExperimentConfluence,
  [SimpleCobranding.WAC_BXPSU_2453_V1]: BXPSU2453Variant1Cobranding,
  [SimpleCobranding.WAC_BXPSU_2453_V2]: BXPSU2453Variant2Cobranding,
  [SimpleCobranding.WAC_BXPSU_2618_V1]: BXPSU2618Variant1Cobranding,
};

export const getCobrandedComponents = (
  cobranding: SimpleCobranding | AdvancedCobranding
): CobrandingComponents => {
  if (isSimpleCobranding(cobranding)) {
    return cobrandingMap[cobranding];
  } else {
    console.warn(
      `[COBRANDING] ${cobranding} is globally configured for advanced-cobranding but corresponding configuration for ${window.location.pathname} was not provided. Loading SimpleCobranding.NO_BRANDING as fallback.`
    );
    return cobrandingMap[SimpleCobranding.NO_COBRANDING];
  }
};
