import { State } from '../reducers';
import { URLParameters } from '../types';
import queryString from 'query-string';
import { applicationSelector, lookupApplication } from '../utilities/applications';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../stores/redux.store';

export interface ApplicationDestination {
  destinationHostname: string | null;
  applicationName: string;
}

const parseContinueUrl = (search: string) => {
  const params: URLParameters = queryString.parse(search);
  if (params.continue) {
    try {
      return new URL(params.continue);
    } catch {
      //
    }
  }
  return null;
};

export const applicationDestinationSelector = createSelector(
  [applicationSelector, (_state: RootState, search: string) => search],
  (applicationData, search) => {
    const continueUrl = parseContinueUrl(search);
    const destinationHostname = (continueUrl && continueUrl.hostname) || null;

    if (applicationData) {
      if (applicationData.application === 'jira' || applicationData.application === 'confluence') {
        return {
          destinationHostname,
          applicationName: applicationData.name,
        };
      }
      return {
        destinationHostname: null,
        applicationName: applicationData.name,
      };
    }

    return null;
  }
);

export default applicationDestinationSelector;
