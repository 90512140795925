import { createContext, useContext } from 'react';
import { State as CobrandingState } from '../../reducers/cobranding-reducer';

import { lookupApplication } from '../applications';

export enum SimpleCobranding {
  NO_COBRANDING = 'no-cobranding',
  BITBUCKET = 'bitbucket',
  COMPASS = 'compass',
  CONFLUENCE = 'confluence',
  JIRA = 'jira',
  OPSGENIE = 'opsgenie',
  STATUSPAGE = 'statuspage',
  TRELLO = 'trello',
  WAC = 'wac',
  ATLAS = 'atlas',
  LOOM = 'loom',
  JIRA_ALIGN = 'jira-align',
  WAC_SIGNUP_OTP_EXPERIMENT_JIRA = 'wac-signup-otp-experiment-jira',
  WAC_SIGNUP_OTP_EXPERIMENT_CONFLUENCE = 'wac-signup-otp-experiment-confluence',
  WAC_BXPSU_2453_V1 = 'bxpsu2453-v1',
  WAC_BXPSU_2453_V2 = 'bxpsu2453-v2',
  WAC_BXPSU_2618_V1 = 'bxpsu2618-v1',
}

export enum AdvancedCobranding {
  DEMO = 'demo',
  ATLAS_66195 = 'atlas-66195',
}

export const isSimpleCobranding = (value: string): value is SimpleCobranding =>
  Object.values<string>(SimpleCobranding).includes(value);

export const isAdvancedCobranding = (value: string): value is AdvancedCobranding =>
  Object.values<string>(AdvancedCobranding).includes(value);

export function getCurrentCobranding(
  cobranding: CobrandingState
): SimpleCobranding | AdvancedCobranding {
  const applicationData = lookupApplication(cobranding);
  if (applicationData) {
    switch (applicationData.application) {
      case 'atlas':
        return SimpleCobranding.ATLAS;
      case 'bitbucket':
        return SimpleCobranding.BITBUCKET;
      case 'compass':
        return SimpleCobranding.COMPASS;
      case 'confluence':
        return SimpleCobranding.CONFLUENCE;
      case 'jira':
        return SimpleCobranding.JIRA;
      case 'loom':
        return SimpleCobranding.LOOM;
      case 'jira-align':
        return SimpleCobranding.JIRA_ALIGN;
      case 'opsgenie':
        return SimpleCobranding.OPSGENIE;
      case 'statuspage':
        return SimpleCobranding.STATUSPAGE;
      case 'trello':
        return SimpleCobranding.TRELLO;
      case 'wac':
        switch (applicationData.experience) {
          case 'otp-jira':
            return SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_JIRA;
          case 'otp-confluence':
            return SimpleCobranding.WAC_SIGNUP_OTP_EXPERIMENT_CONFLUENCE;
          case 'bxpsu2453-v1':
            return SimpleCobranding.WAC_BXPSU_2453_V1;
          case 'bxpsu2453-v2':
            return SimpleCobranding.WAC_BXPSU_2453_V2;
          case 'bxpsu2618-v1':
            return SimpleCobranding.WAC_BXPSU_2618_V1;
          case 'atlas-66195':
            return AdvancedCobranding.ATLAS_66195;
          default:
            return SimpleCobranding.WAC;
        }
      case 'pollinator':
        if (applicationData.experience) {
          if (applicationData.experience.startsWith('jira')) {
            return SimpleCobranding.JIRA;
          }
          if (applicationData.experience.startsWith('confluence')) {
            return SimpleCobranding.CONFLUENCE;
          }
          if (applicationData.experience === 'demo') {
            return AdvancedCobranding.DEMO;
          }
        }
        return SimpleCobranding.NO_COBRANDING;
    }
  }

  return SimpleCobranding.NO_COBRANDING;
}

export interface CobrandingContext {
  cobranding: SimpleCobranding | AdvancedCobranding;
  isMobileApp: boolean;
}

const CobrandingContext = createContext<CobrandingContext>({
  cobranding: SimpleCobranding.NO_COBRANDING,
  isMobileApp: false,
});

export const CobrandingProvider = CobrandingContext.Provider;
export const CobrandingConsumer = CobrandingContext.Consumer;

export const useCobranding = () => useContext<CobrandingContext>(CobrandingContext);
