import React, { ReactNode, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { AnalyticsClientImpl } from '../utilities/analytics/analytics-web-client';
import { captureException } from '../utilities/analytics/error-reporting';
import { getFfsId, initializeFeatureFlagClient } from '../utilities/feature-flags';

type FeatureFlagProviderProps = {
  children: ReactNode;
  analyticsClient: AnalyticsClientImpl;
};

export const FeatureFlagProvider = ({ children, analyticsClient }: FeatureFlagProviderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        const ffsId = getFfsId();
        const client = initializeFeatureFlagClient(analyticsClient);
        await client.ready();

        const flags = client.getFlags();

        Sentry.withScope(scope => {
          Object.keys(flags).forEach(key => scope.setExtra(key, flags[key]));
        });

        if (ffsId) {
          analyticsClient.setFfsId(ffsId);
        }
      } catch (e) {
        console.warn('[FeatureFlagClient] Using default feature flags');
        captureException(e, { message: 'Failed to load @atlassiansox/feature-flag-web-client' });
      } finally {
        setIsLoaded(true);
      }
    };

    if (!isLoaded) {
      load();
    }
  }, [isLoaded, analyticsClient]);

  if (!isLoaded) {
    return null;
  }

  return <>{children}</>;
};
