import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

export function AvatarIcon({ isVerified }: { isVerified: boolean }) {
  return (
    <AvatarIconWrapper>
      <UserIconWrapper>
        <UserIcon />
      </UserIconWrapper>
      <CheckmarkIconWrapper isVerified={isVerified}>
        <CheckmarkIcon />
      </CheckmarkIconWrapper>
    </AvatarIconWrapper>
  );
}

const SIZE_PX = 53;
const COLOR_GREEN = '#22A06B';
const COLOR_TEAL = '#7ED4DB';
const COLOR_BLUE = '#61ACF3';
const COLOR_WHITE = '#FFFFFF';
const TRANSITION_SECONDS = 1;

const AvatarIconWrapper = styled.div({
  height: SIZE_PX,
  position: 'relative',
  width: SIZE_PX,
});

const iconWrapperStyle: CSSProperties = {
  alignItems: 'center',
  borderRadius: '50%',
  display: 'flex',
  height: SIZE_PX,
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  width: SIZE_PX,
};

const CheckmarkIcon = () => (
  <svg aria-hidden={true} width="26" height="19.5" fill={COLOR_WHITE} viewBox="0 0 20 15">
    <path d="M19.279.555c.705.705.725 1.835.06 2.565l-.06.063L8.183 14.279a1.858 1.858 0 0 1-2.565.06l-.063-.06L.883 9.607a1.858 1.858 0 0 1 2.565-2.689l.063.06 3.358 3.359L16.651.555a1.858 1.858 0 0 1 2.565-.06l.063.06Z" />
  </svg>
);

const CheckmarkIconWrapper = styled.div<{ isVerified: boolean }>(({ isVerified }) => ({
  ...iconWrapperStyle,
  backgroundColor: COLOR_GREEN,
  opacity: isVerified ? 1 : 0,
  transition: `opacity ${TRANSITION_SECONDS}s`,
}));

const UserIcon = () => (
  <svg aria-hidden={true} fill={COLOR_WHITE} height="41" viewBox="0 0 28 41" width="28">
    <path d="M0 25.056C0 22.539 2.105 20.5 4.688 20.5h18.624c2.59 0 4.688 2.037 4.688 4.57v10.113c0 7.756-28 7.756-28 0V25.056Z" />
    <path d="M14 18.223c5.155 0 9.333-4.08 9.333-9.112S19.155 0 14 0 4.667 4.08 4.667 9.111c0 5.032 4.178 9.111 9.333 9.111Z" />
  </svg>
);

const UserIconWrapper = styled.div({
  ...iconWrapperStyle,
  background: `linear-gradient(90deg, ${COLOR_TEAL}, ${COLOR_BLUE})`,
});
