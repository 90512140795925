import React from 'react';
import { AtlassianLogo, ConfluenceLogo } from '@atlaskit/logo';

import type { CobrandingComponents } from '../../shared-cobranding';
import { useNewUserContinueUrl } from '../common/hooks';
import ImageRouter from '../components/ImageRouter';
import * as Styled from '../common/styled';

function BXPSU2618Variant1Background({ children }: { children: React.ReactNode }) {
  useNewUserContinueUrl();

  return (
    <Styled.ConfluenceGridContainer>
      <Styled.ConfluenceContainer>
        <Styled.LogoContainerVariant2>
          <ConfluenceLogo appearance="brand" size="large" />
        </Styled.LogoContainerVariant2>
        <Styled.ContentContainer>
          <Styled.WhiteBoxContainer>{children}</Styled.WhiteBoxContainer>
          <ImageRouter productImage="CONFLUENCE" />
        </Styled.ContentContainer>
        <Styled.FooterVariant1>
          <AtlassianLogo appearance="neutral" size="xsmall" />
        </Styled.FooterVariant1>
      </Styled.ConfluenceContainer>
    </Styled.ConfluenceGridContainer>
  );
}

const BXPSU2618Variant1Cobranding: CobrandingComponents = {
  background: BXPSU2618Variant1Background,
  logo: null,
  whiteboxWidth: '400px',
  hideWhiteboxBorder: true,
};

export default BXPSU2618Variant1Cobranding;
