import React from 'react';
import ProfileMenu from './ProfileMenu';
import { useAppSelector } from '../../../../stores/redux.store';
import * as Styled from '../common/styled';
import { JiraProductIllustration } from '../common/svg/jira-product-illustration';
import { ConfluenceProductIllustration } from '../common/svg/confluence-product-illustration';
import { ProductImageType } from './ImageRouter';

type CreateAccountConfirmationProps = {
  readonly productImage: ProductImageType;
};

export default function CreateAccountConfirmation({
  productImage,
}: CreateAccountConfirmationProps) {
  const displayName = useAppSelector(state => state.createAccount.displayName);
  const email = useAppSelector(state => state.createAccount.email);
  return (
    <Styled.ImageContainer>
      <Styled.CreateAccountConfirmationContainer>
        {productImage === 'JIRA' && (
          <Styled.JiraImageContainer>
            <JiraProductIllustration />
          </Styled.JiraImageContainer>
        )}
        {productImage === 'CONFLUENCE' && (
          <Styled.ConfluenceImageContainer>
            <ConfluenceProductIllustration />
          </Styled.ConfluenceImageContainer>
        )}
        <ProfileMenu name={displayName} email={email} />
      </Styled.CreateAccountConfirmationContainer>
    </Styled.ImageContainer>
  );
}
