/* eslint-disable max-params */
import queryString from 'query-string';
import { Base64 } from 'js-base64';
import { CsrfToken, URLParameters, Auth0Config, UserFlow, JWT } from '../types';
import { isBooleanFeatureFlagSet, FeatureFlagId } from './feature-flags';
import H from 'history';

export enum SocialLoginFlowType {
  Auth0 = 'auth0',
  Google = 'google',
  InHouse = 'in-house',
}

export enum LoginType {
  Apple = 'appleLogin',
  Slack = 'slackLogin',
  Microsoft = 'microsoftLogin',
  SSO = 'ssoLogin',
  UnknownAuth0 = 'unknownAuth0Login',
  GoogleButton = 'googleButton',
  GoogleOneTap = 'googleYolo',
}

export type GoogleLoginType = LoginType.GoogleButton | LoginType.GoogleOneTap;

export type InHouseSocialLoginType = LoginType.Apple | LoginType.Slack | LoginType.Microsoft;

export type Auth0LoginType =
  | LoginType.Apple
  | LoginType.Slack
  | LoginType.Microsoft
  | LoginType.SSO
  | LoginType.UnknownAuth0;

export const isAuth0LoginType = (type: string): type is Auth0LoginType => {
  switch (type as LoginType) {
    case LoginType.Apple:
    case LoginType.Slack:
    case LoginType.Microsoft:
    case LoginType.SSO:
      return true;
    default:
      return false;
  }
};

/**
 * Used when we want to ensure that the user picks a specific email address from the social login
 * provider. Using the OIDC standard login_hint parameter still gives the user the option to pick
 * other accounts either on purpose or accidentally.
 */
export interface SocialLoginRecoveryOptions {
  expectedEmail: string;
  recoveryToken: JWT;
}

// common

/**
 * Builds up an OIDC `state` parameter. The `state` parameter is supposed to be used _as_ a CSRF token, and so is guaranteed to
 * be provided as a parameter once the OIDC provider redirects the user's browser back to Atlassian account. This means we can
 * smuggle information through the OIDC flow by packing and unpacking this parameter at the appropriate time.
 */
export const buildState = ({
  location,
  csrfToken,
  source,
  loginType,
  anonymousId,
  userFlow,
  recoveryOptions,
}: {
  location: Location;
  csrfToken: CsrfToken;
  source: string;
  loginType: LoginType;
  anonymousId?: string;
  userFlow?: UserFlow;
  recoveryOptions?: SocialLoginRecoveryOptions;
}) =>
  Base64.encode(
    JSON.stringify({
      csrfToken,
      query: location.search || undefined,
      source,
      loginType,
      anonymousId,
      userFlow,
      expects: recoveryOptions?.expectedEmail,
      recovery: recoveryOptions?.recoveryToken,
    })
  );

// Google

export interface GoogleLoginRedirectParams {
  location: Location;
  source: string;
  recoveryOptions?: SocialLoginRecoveryOptions;
}

export const redirectToGoogleLogin = ({
  location,
  source,
  recoveryOptions,
}: GoogleLoginRedirectParams) => {
  const searchParams: Record<string, string> = queryString.parse(location.search);
  const filteredParams = Object.fromEntries(
    Object.entries(searchParams).filter(([key]) => key !== 'login_hint')
  );
  const { userFlow } = filteredParams;

  const params = {
    ...filteredParams,
    prompt: 'select_account',
    source,
    loginType: LoginType.GoogleButton,
    userFlow,
    recovery: recoveryOptions?.recoveryToken,
  };

  location.assign(
    `${location.protocol}//${location.host}/login/initiate/google?` + queryString.stringify(params)
  );
};

// Auth0

export interface SocialLoginRedirectParams {
  location: Location;
  auth0Config?: Auth0Config;
  csrfToken: CsrfToken;
  source: string;
  email?: string;
  recoveryOptions?: SocialLoginRecoveryOptions;
}

const redirectToSocialLoginInitiate = ({
  location,
  source,
  recoveryOptions,
  loginType,
  loginHint,
}: {
  location: Location;
  source: string;
  recoveryOptions?: SocialLoginRecoveryOptions;
  loginType: LoginType.Microsoft | LoginType.Slack | LoginType.Apple;
  loginHint?: string;
}) => {
  const initiateParams = {
    ...queryString.parse(location.search),
    loginType,
    source,
    recovery: recoveryOptions?.recoveryToken,
    prompt: 'select_account',
    login_hint: loginHint,
  };

  let initiateUriSuffix;
  switch (loginType) {
    case LoginType.Apple:
      initiateUriSuffix = 'apple';
      break;
    case LoginType.Microsoft:
      initiateUriSuffix = 'microsoft';
      break;
    case LoginType.Slack:
      initiateUriSuffix = 'slack';
      break;
  }

  location.assign(
    `${location.protocol}//${location.host}/login/initiate/${initiateUriSuffix}?` +
      queryString.stringify(initiateParams)
  );
};

const isAuthEnabled = ({
  location,
  localStorageKey,
  forceEnableParamName,
  featureFlagName,
}: {
  location: H.Location;
  localStorageKey: string;
  forceEnableParamName: string;
  featureFlagName: FeatureFlagId;
}) => {
  const params: URLParameters = queryString.parse(location.search);
  const forceEnabled = forceEnableParamName in params;
  const featureFlagValue = isBooleanFeatureFlagSet(featureFlagName);

  if (forceEnabled) {
    try {
      localStorage.setItem(localStorageKey, '1');
    } catch (error) {
      console.error(error);
    }
  }

  if (forceEnabled || featureFlagValue) {
    return true;
  } else {
    try {
      return Boolean(localStorage.getItem(localStorageKey));
    } catch (error) {
      console.error(error);
      return false;
    }
  }
};

// Microsoft

export const redirectToMicrosoftLogin = (params: SocialLoginRedirectParams) => {
  const { location, source, recoveryOptions, email } = params;
  redirectToSocialLoginInitiate({
    location,
    source,
    recoveryOptions,
    loginType: LoginType.Microsoft,
    loginHint: email,
  });
};

export const isMicrosoftAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_microsoft',
    forceEnableParamName: 'enableMicrosoft',
    featureFlagName: 'identity.aid_signup.microsoft.auth.enabled',
  });

// Apple

export const redirectToAppleLogin = (params: SocialLoginRedirectParams) => {
  const { location, source, recoveryOptions, email } = params;
  redirectToSocialLoginInitiate({
    location,
    source,
    recoveryOptions,
    loginType: LoginType.Apple,
    loginHint: email,
  });
};

export const isAppleAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_apple',
    forceEnableParamName: 'enableApple',
    featureFlagName: 'identity.aid_signup.apple.auth.enabled',
  });

export const isAppleHiddenEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }

  // Hidden email as defined by <unique-alphanumeric-string>@privaterelay.appleid.com
  const hiddenEmailDomainIdentifier = 'privaterelay.appleid.com';
  const [domain] = email.split('@').slice(-1);
  return domain === hiddenEmailDomainIdentifier;
};

// Slack

export const redirectToSlackLogin = (params: SocialLoginRedirectParams) => {
  const { location, source, recoveryOptions } = params;
  redirectToSocialLoginInitiate({
    location,
    source,
    recoveryOptions,
    loginType: LoginType.Slack,
    // Slack's OIDC implementation expects login_hint to be a JWT and blows up if it's not. If
    // Slack ever supports using an email as the login_hint (like other OIDC providers) then we
    // should remove this line.
    loginHint: undefined,
  });
};

export const isSlackAuthEnabled = (location: H.Location): boolean =>
  isAuthEnabled({
    location,
    localStorageKey: 'enable_slack',
    forceEnableParamName: 'enableSlack',
    featureFlagName: 'identity.sign-in-with-slack.enabled',
  });

export const isGoogleLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('identity.aid-frontend.google.login.outage.advisory.enabled');

export const isMicrosoftLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('identity.aid-frontend.microsoft.login.outage.advisory.enabled');

export const isAppleLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('identity.aid-frontend.apple.login.outage.advisory.enabled');

export const isSlackLoginOutageAdvisoryEnabled = (): boolean =>
  isBooleanFeatureFlagSet('identity.aid-frontend.slack.login.outage.advisory.enabled');
