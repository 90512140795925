import appConfig, { State as AppConfigState } from './app-config-reducer';
import cancelDeletion, { State as CancelDeletionState } from './cancel-deletion-reducer';
import changeEmail, { State as ChangeEmailState } from './change-email-reducer';
import changePassword, { State as ChangePasswordState } from './change-password-reducer';
import cobranding, { State as CobrandingState } from './cobranding-reducer';
import context, { State as ContextState } from './context-reducer';
import continueAs, { State as ContinueAsState } from './continue-as-reducer';
import createAccount, { State as CreateAccountState } from './create-account-reducer';
import { State as CsrfTokenState, hashedCsrfToken, csrfToken } from './csrf-token-reducer';
import expiredPassword, { State as ExpiredPasswordState } from './expired-password-reducer';
import flags, { State as FlagState } from './flag-reducer';
import googleOneTap, { State as GoogleOneTapState } from './google-one-tap-reducer';
import locale, { State as LocaleState } from './locale-reducer';
import login, { State as LoginState } from './login-reducer';
import logout, { State as LogoutState } from './logout-reducer';
import marketingConsent, { State as MarketingConsentState } from './marketing-consent-reducer';
import mfaEnrollment, { State as MfaEnrollmentState } from './mfa-enrollment-reducer';
import mfaPromote, { State as MfaPromoteState } from './mfa-promote-reducer';
import microbranding, { State as MicrobrandingState } from './microbranding-reducer';
import migrateConfirmation, {
  State as migrateConfirmationState,
} from './migrate-confirmation-reducer';
import multiFactor, { State as MultiFactorState } from './multi-factor-reducer';
import welcomeSent, { State as WelcomeSentState } from './welcome-sent-reducer';
import recovery, { State as RecoveryState } from './recovery-reducer';
import resetPassword, { State as ResetPasswordState } from './reset-password-reducer';
import selectAccount, { State as SelectAccountState } from './select-account-reducer';
import serverError, { State as ServerErrorState } from './server-error-reducer';
import signup, { State as SignupState } from './signup-reducer';
import socialLoginRecovery, {
  State as SocialLoginRecoveryState,
} from './social-login-recovery-reducer';
import stepUp, { State as StepUpState } from './step-up-reducer';
import stepUpSso, { State as StepUpSsoState } from './step-up-sso-reducer';
import user, { State as UserState } from './user-reducer';
import verifyEmail, { State as VerifyEmailState } from './verify-email-reducer';
import verifyEmailOtp, { State as VerifyEmailOtpState } from './verify-email-otp-reducer';
import verifyEmailSent, { State as VerifyEmailSentState } from './verify-email-sent-reducer';
import welcome, { State as WelcomeState } from './welcome-reducer';
import loginConsent, { State as LoginConsentState } from './login-consent-reducer';

export interface State {
  appConfig: AppConfigState;
  csrfToken: CsrfTokenState;
  hashedCsrfToken: CsrfTokenState;
  login: LoginState;
  logout: LogoutState;
  welcome: WelcomeState;
  marketingConsent: MarketingConsentState;
  mfaEnrollment: MfaEnrollmentState;
  mfaPromote: MfaPromoteState;
  recovery: RecoveryState;
  microbranding: MicrobrandingState;
  multiFactor: MultiFactorState;
  resetPassword: ResetPasswordState;
  signup: SignupState;
  user: UserState;
  flags: FlagState;
  continueAs: ContinueAsState;
  selectAccount: SelectAccountState;
  cancelDeletion: CancelDeletionState;
  expiredPassword: ExpiredPasswordState;
  changePassword: ChangePasswordState;
  changeEmail: ChangeEmailState;
  googleOneTap: GoogleOneTapState;
  createAccount: CreateAccountState;
  context: ContextState;
  migrateConfirmation: migrateConfirmationState;
  socialLoginRecovery: SocialLoginRecoveryState;
  verifyEmail: VerifyEmailState;
  verifyEmailSent: VerifyEmailSentState;
  serverError: ServerErrorState;
  cobranding: CobrandingState;
  welcomeSent: WelcomeSentState;
  stepUp: StepUpState;
  stepUpSso: StepUpSsoState;
  locale: LocaleState;
  verifyEmailOtp: VerifyEmailOtpState;
  loginConsent: LoginConsentState;
}

export const getReducers = () => ({
  appConfig,
  csrfToken,
  hashedCsrfToken,
  login,
  logout,
  marketingConsent,
  mfaEnrollment,
  mfaPromote,
  microbranding,
  multiFactor,
  recovery,
  resetPassword,
  signup,
  user,
  flags,
  continueAs,
  selectAccount,
  cancelDeletion,
  expiredPassword,
  googleOneTap,
  changePassword,
  changeEmail,
  createAccount,
  welcome,
  context,
  migrateConfirmation,
  socialLoginRecovery,
  verifyEmail,
  verifyEmailSent,
  serverError,
  cobranding,
  stepUp,
  stepUpSso,
  welcomeSent,
  locale,
  verifyEmailOtp,
  loginConsent,
});
