import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export function useNewUserContinueUrl() {
  // This hook adds the ?n2n=true query param to the continue url when on the CreateAccountConfirmationPage
  const { pathname } = useLocation();
  const [, setQueryParams] = useSearchParams();
  useEffect(() => {
    if (
      ['/login/google/confirmation', '/login/social/confirmation', '/signup/confirmation'].includes(
        pathname
      )
    ) {
      setQueryParams(urlParams => {
        try {
          const continueQueryParam = urlParams.get('continue') || '';
          const continueUrl = new URL(continueQueryParam);
          if (!continueUrl.searchParams.has('n2n')) {
            continueUrl.searchParams.set('n2n', 'true');
            urlParams.set('continue', continueUrl.toString());
          }
        } catch (error) {
          // ignore invalid continue url
        }
        return urlParams;
      });
    }
  }, [pathname, setQueryParams]);
}
