import Cookie from 'js-cookie';

/**
 * https://github.com/segmentio/analytics.js-core/blob/1e769c658d427e547e9fbe1e640fa0a5c15baaab/lib/user.js#L102
 */
const USER_ID_COOKIE_KEY = 'ajs_anonymous_id';

export function getAnonymousId(): string | undefined {
  const anonymousId = Cookie.get(USER_ID_COOKIE_KEY);
  return anonymousId && anonymousId.replace(/(^")|("$)/g, '');
}
