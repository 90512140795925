import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { getReducers } from '../reducers';
import { idAuthMfaMiddleware } from '../middleware/idauth-mfa';
import type { PreloadedState } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const rootReducer = combineReducers(getReducers());

export const createAppStore = (preloadedState: PreloadedState<RootState> = {}) =>
  configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(idAuthMfaMiddleware),
  });

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof createAppStore>;
export type AppDispatch = AppStore['dispatch'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
