/* eslint-disable @atlaskit/design-system/prefer-primitives */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

import styled from '@emotion/styled';
export var Background = styled.div({
  position: 'fixed',
  background: '#fff',
  height: '100%',
  width: '100%'
});
export var DarkBackground = styled.div({
  position: 'fixed',
  background: '#2c333a',
  width: '100%',
  height: '100%'
});