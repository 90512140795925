import { Action } from '../types';
import { Application } from '../utilities/applications';

export interface StaticCobranding {
  application?: Application;
  derivedApplication?: Application;
  productHint?: string;
  product?: Application;
  experience?: string;
}

export type State = StaticCobranding;

export const defaultState: State = {};

const cobranding = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default cobranding;
