import { useCallbackOne } from 'use-memo-one';
import UIAnalyticsEvent from '../events/UIAnalyticsEvent';
import { useAnalyticsContext } from './useAnalyticsContext';
export function useAnalyticsEvents() {
  var analyticsContext = useAnalyticsContext();
  var createAnalyticsEvent = useCallbackOne(function (payload) {
    return new UIAnalyticsEvent({
      context: analyticsContext.getAtlaskitAnalyticsContext(),
      handlers: analyticsContext.getAtlaskitAnalyticsEventHandlers(),
      payload: payload
    });
  }, [analyticsContext]);
  return {
    createAnalyticsEvent: createAnalyticsEvent
  };
}