import React, { useLayoutEffect } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import { useLocation } from 'react-router-dom';

type BrowserMetricsBoundaryProps = {
  children: React.ReactNode;
};

export const BrowserMetricsBoundary = ({ children }: BrowserMetricsBoundaryProps) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    browserMetrics.startPageLoad();
  }, [pathname]);

  return <>{children}</>;
};
