import * as Sentry from '@sentry/browser';
import { Microbranding } from '../../reducers/microbranding-reducer';
import { currentEnv, isFedM } from '../env';
import { isMobileOidc } from '../oidc/oidc';
import { getAnonymousId } from './segment-io';

type SentryPayload = Record<string, unknown> | Error;

// Only turn on debug for non production and non test runs (i.e. Jest);
const debug = currentEnv !== 'prod' && process.env.NODE_ENV !== 'test';

// https://hello.atlassian.net/wiki/spaces/OBSERVABILITY/pages/3370131692/Sentry+FedRAMP+-+Getting+Started#Sentry-DSN-in-FedRAMP
const fedMSentryDSN = 'https://1c864dd6ac671ce6296e7cc6710a21a0@sentry.atlassian-us-gov-mod.com/22';
const commercialSentryDSN =
  'https://1271fcf5ddad45a1846153219eb726bd@o55978.ingest.sentry.io/4504172801622016';

Sentry.init({
  dsn: isFedM() ? fedMSentryDSN : commercialSentryDSN,
  debug,
  environment: currentEnv,
  release: process.env.REACT_APP_VERSION || 'no-version',
  sampleRate: 0.1,
  beforeSend(event) {
    // Remove URL queryparams, they can contain PII
    if (event.request?.url) {
      event.request.url = event.request.url.split('?')[0];
      delete event.request.query_string;
    }

    return event;
  },
  beforeBreadcrumb(breadcrumb) {
    // Don't send breadcrumbs that may contain PII
    if (['navigation', 'fetch', 'xhr'].includes(breadcrumb.category || '')) {
      return null;
    }

    return breadcrumb;
  },
  denyUrls: [
    /^chrome:\/\//,
    /^chrome-extension:\/\//,
    /accounts\.google\.com/, // Google OneTap
    /recaptcha.net/, // Google ReCAPTCHA
  ],
  ignoreErrors: [
    /^null/,
    /Loading chunk.\d+.failed/,
    /^[^.]+\w+ is not defined/, // Ignore injected globals
    'NetworkError',
    'Failed to fetch',
    'SecurityError', // Incognito errors
    'Object Not Found Matching Id', // Selenium webdriver
    'Non-Error promise rejection captured with value:',
    'ResizeObserver loop limit exceeded', // From browser extensions, ResizeObserver isn't used
  ],
});

export function initClient(microbranding: Microbranding) {
  const userId = getAnonymousId();

  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });

    // We're not going to send all of microbranding just in case someone
    // puts PII into microbranding later on
    scope.setExtra('isEmbedded', microbranding.isEmbedded);
    scope.setExtra('isMobileOidc', isMobileOidc(microbranding.oidcContext));
  });
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  if (!breadcrumb.level) {
    breadcrumb.level = 'info';
  }
  Sentry.addBreadcrumb(breadcrumb);
}

export function setLocale(locale: string | undefined) {
  if (locale) {
    Sentry.configureScope(scope => {
      scope.setTag('locale', locale);
    });
  }
}

export function captureException(ex: Error, payload?: SentryPayload) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.keys(payload).forEach(key => {
        scope.setExtra(key, payload[key]);
      });

      Sentry.captureException(ex);
    });
  } else {
    Sentry.captureException(ex);
  }
}

export function captureErrorMessage(msg: string, payload: SentryPayload = {}) {
  captureMessage(msg, payload, 'error');
}

export function captureMessage(
  msg: string,
  payload: SentryPayload,
  severity: Sentry.SeverityLevel
) {
  if (payload) {
    Sentry.withScope(scope => {
      Object.keys(payload).forEach(key => {
        scope.setExtra(key, payload[key]);
      });

      Sentry.captureMessage(msg, severity);
    });
  } else {
    Sentry.captureMessage(msg, severity);
  }
}
