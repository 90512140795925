import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export enum LoginHintType {
  EmailAddress = 'email-address',
  NotEmailAddress = 'not-email-address',
}

export interface LoginHint {
  type: LoginHintType;
  value: string;
}

const NEGATED_EMAIL_PREFIX = 'not:';

function parseLoginHint(loginHint: string | null): LoginHint | undefined {
  if (!loginHint) {
    return undefined;
  }

  if (loginHint.startsWith(NEGATED_EMAIL_PREFIX)) {
    return {
      type: LoginHintType.NotEmailAddress,
      value: loginHint.substring(NEGATED_EMAIL_PREFIX.length),
    };
  }

  return {
    type: LoginHintType.EmailAddress,
    value: loginHint,
  };
}

export const useLoginHint = (): LoginHint | undefined => {
  const { search } = useLocation();

  return useMemo(() => {
    const loginHint = new URLSearchParams(search).get('login_hint');
    return parseLoginHint(loginHint);
  }, [search]);
};

export const getEmailFromLoginHint = (loginHint: LoginHint | undefined) => {
  if (loginHint?.type === LoginHintType.EmailAddress) {
    return loginHint.value;
  }
};
