import React from 'react';
import { AtlassianLogo, ConfluenceLogo } from '@atlaskit/logo';

import { CobrandingComponents } from '../shared-cobranding';
import * as Styled from './common/styled';

function BlankBackgroundWithLogo({ children }: { children: React.ReactNode }) {
  return (
    <Styled.WacSignupOTPExperimentContainer>
      <Styled.WacSignupOTPExperimentGridContainer>
        <Styled.WacSignupOTPExperimentContentContainer>
          <Styled.LogoContainer>
            <ConfluenceLogo appearance="brand" size="large" />
          </Styled.LogoContainer>
          <Styled.ContentContainer>
            <Styled.WhiteBoxContainer>{children}</Styled.WhiteBoxContainer>
          </Styled.ContentContainer>
        </Styled.WacSignupOTPExperimentContentContainer>
      </Styled.WacSignupOTPExperimentGridContainer>
      <Styled.WacSignupOTPExperimentLogoWrapper>
        <AtlassianLogo appearance="brand" size="small" />
      </Styled.WacSignupOTPExperimentLogoWrapper>
    </Styled.WacSignupOTPExperimentContainer>
  );
}

const WacSignupOTPExperimentConfluence: CobrandingComponents = {
  background: BlankBackgroundWithLogo,
  logo: null,
  whiteboxWidth: '460px',
  hideWhiteboxBorder: true,
};

export default WacSignupOTPExperimentConfluence;
