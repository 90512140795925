import { GoogleLoginType, InHouseSocialLoginType, LoginType } from '../utilities/social-login';
import { Action, ConfirmationErrorCode, ConfirmationFieldError, UserFlow } from '../types';

interface BaseState {
  formError?: ConfirmationErrorCode;
  fieldErrors: ConfirmationFieldError;
  redirectTo: string | null;
  isLoading?: boolean;
  userFlow?: UserFlow;

  source?: string;
  loginType?: LoginType;
  displayName?: string;
  email?: string;
  accessToken?: string;
  idToken?: string;
  createNeeded?: boolean;
  verifyNeeded?: boolean;
  confirmationToken?: string;
  mfaToken?: string;
}

export interface GoogleState extends BaseState {
  source: string;
  loginType: GoogleLoginType;
  displayName: string;
  email: string;
  accessToken: string;
  idToken: string;
  createNeeded: boolean;
  verifyNeeded: boolean;
  confirmationToken: string;
}

export interface InHouseSocialLoginState extends BaseState {
  loginType: InHouseSocialLoginType;
  displayName: string;
  email: string;
  confirmationToken: string;
  mfaToken?: string;
  createNeeded: boolean;
  verifyNeeded: boolean;
}

export type State = BaseState | GoogleState | InHouseSocialLoginState;

export const defaultState: BaseState = {
  formError: undefined,
  fieldErrors: {},
  redirectTo: null,
  isLoading: false,
  source: undefined,
  loginType: undefined,
  displayName: undefined,
  email: undefined,
  accessToken: undefined,
  idToken: undefined,
  createNeeded: undefined,
  verifyNeeded: undefined,
  confirmationToken: undefined,
  mfaToken: undefined,
};

const createAccount = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'CONFIRMATION_RESET':
      return defaultState;
    case 'CONFIRMATION_REQUEST':
      return {
        ...state,
        fieldErrors: {},
        formError: undefined,
        isLoading: true,
      };
    case 'CONFIRMATION_REQUEST_SUCCESS':
      return {
        ...state,
        fieldErrors: {},
        isLoading: false,
        redirectTo: action.redirectTo,
      };
    case 'CONFIRMATION_RESEND_SUCCESS':
      return {
        ...(state as InHouseSocialLoginState),
        mfaToken: action.mfaToken,
      };
    case 'CONFIRMATION_REQUEST_FAILED':
      return {
        ...state,
        formError: action.error,
        isLoading: false,
      };
    case 'CONFIRMATION_SHOW_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: action.errors,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default createAccount;
