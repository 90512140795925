import React from 'react';
import ProfileMenu from './ProfileMenu';
import { useAppSelector } from '../../../../stores/redux.store';
import * as Styled from '../common/styled';
import { JiraProductIllustration } from '../common/svg/jira-product-illustration';
import { ConfluenceProductIllustration } from '../common/svg/confluence-product-illustration';
import { ProductImageType } from './ImageRouter';

type VerifyEmailOtpProps = {
  readonly productImage: ProductImageType;
};

export default function VerifyEmailOtp({ productImage }: VerifyEmailOtpProps) {
  const email = useAppSelector(state => state.verifyEmailOtp.email);
  const isOtpVerified = useAppSelector(state => Boolean(state.verifyEmailOtp.redirectTo));

  return (
    <Styled.ImageContainer>
      <Styled.OtpContainer>
        {productImage === 'JIRA' && (
          <Styled.JiraImageContainer>
            <JiraProductIllustration />
          </Styled.JiraImageContainer>
        )}
        {productImage === 'CONFLUENCE' && (
          <Styled.ConfluenceImageContainer>
            <ConfluenceProductIllustration />
          </Styled.ConfluenceImageContainer>
        )}
        <ProfileMenu email={email} isVerified={isOtpVerified} />
      </Styled.OtpContainer>
    </Styled.ImageContainer>
  );
}
