import { lazy } from 'react';

export const MfaAppsPage = lazy(() => import('../components/MfaAppsPage'));
export const VerifyEmailSentPage = lazy(() => import('./VerifyEmailSentPage'));
export const ReverifyEmailSentPage = lazy(() => import('./ReverifyEmailSentPage'));
export const ExpiredPasswordPage = lazy(() => import('./ExpiredPasswordPage'));
export const MfaEnrollmentPage = lazy(() => import('./MfaEnrollmentPage'));
export const MfaPromotePage = lazy(() => import('./MfaPromotePage'));
export const MultiFactorPage = lazy(() => import('./MultiFactorPage'));
export const LoginConsentNoticePage = lazy(() => import('./LoginConsentNoticePage'));
export const CancelAccountDeletionPage = lazy(() => import('./CancelAccountDeletionPage'));
export const InactiveAccountPage = lazy(() => import('./InactiveAccountPage'));
export const PendingDeletionPage = lazy(() => import('./PendingDeletionPage'));
export const DeletionRequestConfirmationPage = lazy(() =>
  import('./DeletionRequestConfirmationPage')
);

export const ChangePasswordPage = lazy(() => import('./ChangePasswordPage'));
export const SocialLoginRecoveryPage = lazy(() => import('./SocialLoginRecoveryPage'));
export const VerifyOrReverifyEmailSentPage = lazy(() => import('./VerifyOrReverifyEmailSentPage'));
export const VerifyOrReverifyEmailErrorPage = lazy(() =>
  import('./VerifyOrReverifyEmailErrorPage')
);
export const WelcomeSentPage = lazy(() => import('./WelcomeSentPage'));
export const LogoutPage = lazy(() => import('./LogoutPage'));
export const StepUpSsoPage = lazy(() => import('./StepUpSsoPage'));
export const StepUpStartPage = lazy(() => import('./StepUpStartPage'));
export const EmailConfirmationPage = lazy(() => import('./EmailConfirmationPage'));
export const AuthCallbackErrorPage = lazy(() => import('./AuthCallbackErrorPage'));

export const SecurityExpiredLinkPage = lazy(() => import('./SecurityExpiredLinkPage'));
export const SecuritySuccessPage = lazy(() => import('./SecuritySuccessPage'));

export const SignupPage = lazy(() => import('./SignupPage'));
export const UnauthorizedPage = lazy(() => import('./UnauthorizedPage'));
export const RemoveAccountPage = lazy(() => import('./RemoveAccountPage'));
export const SelectAccountPage = lazy(() => import('./SelectAccountPage'));
export const ContinueAsPage = lazy(() => import('./ContinueAsPage'));
export const CreateAccountConfirmationPage = lazy(() => import('./CreateAccountConfirmationPage'));
export const MigrateAccountConfirmationPage = lazy(() =>
  import('./MigrateAccountConfirmationPage')
);
export const RecoveryPage = lazy(() => import('./RecoveryPage'));

export const ChooseMethodPage = lazy(() => import('./MfaEnrollmentChooseMethodPage'));

export const ConfigurePhonePage = lazy(() => import('./MfaEnrollmentConfigurePhonePage'));
export const ConfigureAppPage = lazy(() => import('./MfaEnrollmentConfigureAppPage'));
export const SaveRecoveryKeyPage = lazy(() => import('./MfaEnrollmentSaveRecoveryKeyPage'));
export const RegisterSecurityKeyPage = lazy(() => import('./MfaEnrollmentRegisterSecurityKeyPage'));

export const LoginPage = lazy(() => import('./LoginPage'));

// Migrated to new folder structure of `src/pages`:
export const WelcomePage = lazy(() => import('../pages/signup/welcome/page'));
export const VerifyEmailOtpPage = lazy(() => import('../pages/signup/verify-email/otp/page'));
export const ResetPasswordPage = lazy(() => import('../pages/login/reset-password/page'));
