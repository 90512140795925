import React from 'react';
import styled from '@emotion/styled';

import { AvatarIcon } from './AvatarIcon';

type ProfileMenuProps = {
  name?: string;
  email?: string;
  isVerified?: boolean;
};

export default function ProfileMenu({ name, email, isVerified = false }: ProfileMenuProps) {
  return (
    <ProfileMenuWrapper>
      <AvatarIcon isVerified={isVerified} />
      <DetailsWrapper>
        <Name title={name}>
          {name || <SkeletonText w="92px" h="0.375rem" style={{ margin: '0' }} />}
        </Name>
        <Email title={email}>{email || <SkeletonText w="92px" h="0.375rem" />}</Email>
        <SkeletonText w="92px" h="0.375rem" />
        <SkeletonText w="56px" h="0.375rem" />
      </DetailsWrapper>
    </ProfileMenuWrapper>
  );
}

const ProfileMenuWrapper = styled.section({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '18px 24px',
  gap: '12px',
  borderRadius: '8px',
  boxShadow: '0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50px',
  right: '20px',
});

const DetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '152px',
});

const Name = styled.small({
  fontSize: '0.75rem',
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '155px',
});

const Email = styled.small({
  fontSize: '0.75rem',
  margin: 0,
  marginTop: '6px',
  marginBottom: '9px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '155px',
});

export const SkeletonText = styled.span<{ w: string | number; h: string | number }>(
  {
    display: 'inline-block',
    borderRadius: '12px',
    backgroundColor: '#DCDFE4',
    margin: '0',
    marginTop: '6px',
    marginBottom: '9px',
  },
  ({ w, h }) => ({ width: w, height: h })
);
