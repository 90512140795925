import { OidcDisplay, OidcContext } from '../../types';

export function isOidcDisplayMode(context: OidcContext | null, ...display: Array<OidcDisplay>) {
  if (!context || !context.display) {
    return false;
  }

  return display.includes(context.display);
}

export function isMobileOidc(context: OidcContext | null) {
  // currently we assume that any oidc with proper display param is a mobile oidc
  return !!context && !!context.display;
}
