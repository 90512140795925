import { State as Microbranding } from '../reducers/microbranding-reducer';
import { AppConfig, JWT, LoginFieldError, LoginGlobalError, OidcContext } from '../types';
import { screenSize } from './style-utils';
import { State as UserState } from '../reducers/user-reducer';
import { defaultState, State as LoginState } from '../reducers/login-reducer';
import { State as LogoutState } from '../reducers/logout-reducer';
import { State as ContinueAsState } from '../reducers/continue-as-reducer';
import { State as CancelDeletionState } from '../reducers/cancel-deletion-reducer';
import { State as SelectAccountState } from '../reducers/select-account-reducer';
import { isMobileOidc } from './oidc/oidc';
import { State as ChangeEmailState } from '../reducers/change-email-reducer';
import {
  defaultState as changePasswordDefaultState,
  State as ChangePasswordState,
} from '../reducers/change-password-reducer';
import { State as CreateAccountState } from '../reducers/create-account-reducer';
import { State as ContextState } from '../reducers/context-reducer';
import { State as MigrateConfirmationState } from '../reducers/migrate-confirmation-reducer';
import { State as ServerErrorState } from '../reducers/server-error-reducer';
import {
  defaultState as signupDefaultState,
  State as SignupState,
} from '../reducers/signup-reducer';
import {
  defaultState as welcomeDefaultState,
  State as WelcomeState,
} from '../reducers/welcome-reducer';
import {
  State as VerifyEmailOtpState,
  defaultState as VerifyEmailOtpDefaultState,
} from '../reducers/verify-email-otp-reducer';
import { State as SocialLoginRecoveryState } from '../reducers/social-login-recovery-reducer';
import { State as VerifyEmailState } from '../reducers/verify-email-reducer';
import { State as WelcomeSentState } from '../reducers/welcome-sent-reducer';
import { State as VerifyEmailSentState } from '../reducers/verify-email-sent-reducer';
import { State as CobrandingState } from '../reducers/cobranding-reducer';
import { State as LocaleState } from '../reducers/locale-reducer';

import { AnalyticsClient, AnalyticsClientImpl } from './analytics/analytics-web-client';
import { State as MultiFactorState } from '../reducers/multi-factor-reducer';
import { defaultState as multiFactorDefaultState } from '../reducers/multi-factor-reducer';
import { currentPerimeter, idacOrigin } from './env';
import { captureException } from './analytics/error-reporting';
import { State as StepUp } from '../reducers/step-up-reducer';
import { State as StepUpSso } from '../reducers/step-up-sso-reducer';
import {
  State as LoginConsentState,
  defaultState as LoginConsentDefaultState,
} from '../reducers/login-consent-reducer';

/**
 * This is what comes as a json string from server
 */
export interface PreloadedAppState {
  csrfToken: string;
  hashedCsrfToken: string;
  appConfig: AppConfig;
  locale: string;
  tenantCloudId?: string;
  userId?: string;
  oidcContext?: OidcContext;
  user?: { email: string };
  continueAs?: ContinueAsState;
  cancelDeletion?: CancelDeletionState;
  selectAccount?: SelectAccountState;
  login?: {
    error?: LoginGlobalError;
    fieldErrors: LoginFieldError;
    recoveryToken?: JWT;
  };
  logout?: LogoutState;
  createAccount?: CreateAccountState;
  migrateConfirmation?: MigrateConfirmationState;
  signup?: {
    autoSubmit?: boolean;
  };
  welcome?: WelcomeState;
  socialLoginRecovery?: SocialLoginRecoveryState;
  verifyEmail?: VerifyEmailState;
  verifyEmailSent?: VerifyEmailSentState;
  welcomeSent?: WelcomeSentState;
  error?: ServerErrorState;
  changeEmail?: ChangeEmailState;
  changePassword?: ChangePasswordState;
  cobranding: CobrandingState;
  multiFactor?: MultiFactorState;
  stepUp?: StepUp;
  stepUpSso?: StepUpSso;
  cspNonce: string;
  verifyEmailOtp?: VerifyEmailOtpState;
  loginConsent?: LoginConsentState;
}

interface FetchedAppState {
  appConfig: AppConfig;
  locale: string;
}

/**
 * This is the state with which we hydrate redux store on application start
 */
interface InitialStoreState {
  microbranding: Microbranding;
  csrfToken?: string;
  hashedCsrfToken?: string;
  appConfig?: AppConfig;
  locale?: LocaleState;
  user?: UserState;
  login?: LoginState;
  logout?: LogoutState;
  continueAs?: ContinueAsState;
  cancelDeletion?: CancelDeletionState;
  selectAccount?: SelectAccountState;
  createAccount?: CreateAccountState;
  context: ContextState;
  migrateConfirmation?: MigrateConfirmationState;
  signup?: SignupState;
  welcome?: WelcomeState;
  socialLoginRecovery?: SocialLoginRecoveryState;
  verifyEmail?: VerifyEmailState;
  verifyEmailSent?: VerifyEmailSentState;
  welcomeSent?: WelcomeSentState;
  serverError?: ServerErrorState;
  changeEmail?: ChangeEmailState;
  changePassword?: ChangePasswordState;
  cobranding?: CobrandingState;
  multiFactor?: MultiFactorState;
  verifyEmailOtp?: VerifyEmailOtpState;
  stepUp?: StepUp;
  stepUpSso?: StepUpSso;
  loginConsent?: LoginConsentState;
}

export interface InitialState {
  initialStoreState: InitialStoreState;
  cspNonce: string;
}

/**
 * Reads data-app-state attribute from <body> tag
 * Note that this will be available only if we render frontend from one of our services, e.g. not mfa page
 */
function preloadAppState(): PreloadedAppState | null {
  try {
    const el = document.body;
    return JSON.parse((el && el.getAttribute('data-app-state')) || 'null');
  } catch (e) {
    return null;
  }
}

async function fetchAppState(): Promise<FetchedAppState | null> {
  try {
    return await Promise.race([
      fetch(`${idacOrigin}/frontend/state`, { mode: 'cors', credentials: 'include' }).then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(`Failed to fetch app state: ${res.status} ${res.statusText}`);
        }
      }),
      new Promise<never>((_, reject) => {
        const timeoutMs = 10_000;
        setTimeout(
          () => reject(new Error(`Fetching app state timed out after ${timeoutMs}ms`)),
          timeoutMs
        );
      }),
    ]);
  } catch (e) {
    captureException(e);
    return null;
  }
}

function buildMicrobranding(preloaded: PreloadedAppState | null): Microbranding {
  const isEmbedded = /AtlassianMobileApp|HipChat/.test(navigator.userAgent);
  const isMobileApp =
    isEmbedded || (preloaded && isMobileOidc(preloaded.oidcContext || null)) || false;
  return {
    isEmbedded,
    isAnyMobile: screenSize() === 'mobile' || isMobileApp,
    isMobileApp,
    screenSize: screenSize(),
    oidcContext: (preloaded && preloaded.oidcContext) || null,
  };
}

export async function buildInitialState(): Promise<InitialState> {
  const preloaded = preloadAppState();

  const microbranding = buildMicrobranding(preloaded);

  if (!preloaded) {
    const fetched = await fetchAppState();

    return {
      initialStoreState: {
        csrfToken: undefined,
        user: undefined,
        login: undefined,
        logout: undefined,
        appConfig: fetched?.appConfig,
        locale: fetched?.locale,
        continueAs: undefined,
        cancelDeletion: undefined,
        selectAccount: undefined,
        microbranding,
        createAccount: undefined,
        context: {
          tenantCloudId: undefined,
          userId: undefined,
        },
        migrateConfirmation: undefined,
        welcome: undefined,
        socialLoginRecovery: undefined,
        verifyEmail: undefined,
        verifyEmailSent: undefined,
        changeEmail: undefined,
        changePassword: undefined,
        welcomeSent: undefined,
        serverError: undefined,
        cobranding: undefined,
        stepUp: undefined,
        stepUpSso: undefined,
        verifyEmailOtp: undefined,
        loginConsent: undefined,
      },
      cspNonce: '',
    };
  }

  const loginState = preloaded.login && {
    ...defaultState,
    ...preloaded.login,
  };

  return {
    initialStoreState: {
      csrfToken: preloaded.csrfToken,
      hashedCsrfToken: preloaded.hashedCsrfToken,
      user: preloaded.user,
      login: loginState,
      logout: preloaded.logout,
      appConfig: preloaded.appConfig,
      locale: preloaded.locale,
      continueAs: preloaded.continueAs,
      cancelDeletion: preloaded.cancelDeletion,
      selectAccount: preloaded.selectAccount,
      microbranding,
      createAccount: preloaded.createAccount,
      context: {
        tenantCloudId: preloaded.tenantCloudId,
        userId: preloaded.userId,
      },
      migrateConfirmation: preloaded.migrateConfirmation,
      signup: {
        ...signupDefaultState,
        ...preloaded.signup,
      },
      welcome: {
        ...welcomeDefaultState,
        ...preloaded.welcome,
      },
      socialLoginRecovery: preloaded.socialLoginRecovery,
      verifyEmail: preloaded.verifyEmail,
      verifyEmailSent: preloaded.verifyEmailSent,
      changeEmail: preloaded.changeEmail,
      changePassword: {
        ...changePasswordDefaultState,
        ...preloaded.changePassword,
      },
      welcomeSent: preloaded.welcomeSent,
      serverError: preloaded.error,
      cobranding: preloaded.cobranding,
      multiFactor: {
        ...multiFactorDefaultState,
        ...preloaded.multiFactor,
      },
      stepUp: preloaded.stepUp,
      stepUpSso: preloaded.stepUpSso,
      verifyEmailOtp: {
        ...VerifyEmailOtpDefaultState,
        ...preloaded.verifyEmailOtp,
      },
      loginConsent: {
        ...LoginConsentDefaultState,
        ...preloaded.loginConsent,
      },
    },
    cspNonce: preloaded.cspNonce,
  };
}

export function createAnalyticsClient(initialState: InitialState): AnalyticsClientImpl {
  const { microbranding, cobranding, context } = initialState.initialStoreState;
  const locale = undefined;

  return new AnalyticsClientImpl({
    microbranding,
    cobranding: cobranding || {},
    locale,
    tenantCloudId: context.tenantCloudId,
    userId: context.userId,
    client: undefined,
  });
}

export function startupAnalyticEvent(analyticsClient: AnalyticsClient) {
  analyticsClient.operationalEvent({
    page: 'unknown',
    action: 'loadPage',
    subject: 'sli',
  });
}

export function pushGlobals(appConfig: AppConfig | undefined, cspNonce: string) {
  window.segmentIoKey = appConfig && appConfig.segmentIoKey;
  window.cspNonce = cspNonce;
}

export function initCastle(appConfig: AppConfig) {
  if (currentPerimeter !== 'commercial') {
    return; // Castle is not enabled for FedRAMP
  }

  if (appConfig.castlePublishableApiKey) {
    import('@castleio/castle-js').then(({ configure }) => {
      configure({ pk: appConfig.castlePublishableApiKey });
    });
  }
}
