import React from 'react';
import { JiraLogo, AtlassianLogo } from '@atlaskit/logo';
import { JiraBackground } from '@atlassian/product-wireframes';

import type { CobrandingComponents } from '../../shared-cobranding';
import * as Styled from '../common/styled';
import { useNewUserContinueUrl } from '../common/hooks';

function BXPSU2453Variant1Background({ children }: { children: React.ReactNode }) {
  useNewUserContinueUrl();

  return (
    <JiraBackground>
      <Styled.GridContainer>
        <Styled.ModalContainer>
          <Styled.LogoContainerVariant1>
            <JiraLogo appearance="brand" size="large" />
          </Styled.LogoContainerVariant1>
          <Styled.ContentContainer>
            <Styled.WhiteBoxContainer>{children}</Styled.WhiteBoxContainer>
          </Styled.ContentContainer>
          <Styled.FooterVariant1>
            <AtlassianLogo appearance="neutral" size="xsmall" />
          </Styled.FooterVariant1>
        </Styled.ModalContainer>
      </Styled.GridContainer>
    </JiraBackground>
  );
}

const BXPSU2453Variant1Cobranding: CobrandingComponents = {
  background: BXPSU2453Variant1Background,
  logo: null,
  whiteboxWidth: '400px',
  hideWhiteboxBorder: true,
};

export default BXPSU2453Variant1Cobranding;
